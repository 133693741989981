* {
  font-family: "Quicksand", sans-serif;
  margin: 0;
  padding: 0%;
}

.App {
  text-align: center;
  /* background-color: white; */
  background-color: white;
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
}
.triangle {
  border: 2px solid black;
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: 5px solid #686ea2;
  border-right: 5px solid #686ea2;
  width: 0px;
  height: 0px;
}

.top_section {
  display: flex;
  /* border: 2px solid red; */
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  background-color: white;
  color: #757575;
  border-bottom: 1px solid #9f9f9f;
}

.top_left_section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* border: 2px solid; */
  width: 38%;
  height: 100%;
  align-items: center;
  font-size: 14px;
}

.top_right_section {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* border: 2px solid; */
  width: 35%;
  height: 100%;
  align-items: center;
}

.top_right_section_icon_container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  /* border: 2px solid blue; */
  align-items: center;
}

.top_right_section_icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* navbar css start */

.navbar_container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  padding-top: 1%;
  padding-bottom: 1%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  transition: padding-top 0.9s, padding-bottom 0.9s;
}

.navbar_sticky {
  position: sticky;
  top: 0;
  z-index: 3;
  padding-top: 0%;
  padding-bottom: 0%;
  box-shadow: 0px 8px 10px black;
  transition: padding-top 0.9s, padding-bottom 0.9s, box-shadow 2s;
}

.navbar_left {
  width: 30%;
}

.navbar_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 70%;
  justify-content: flex-end;
  gap: 2%;
}

.navbar_right_link {
  /* width: 620px;
  display: flex;
  gap: 8%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end; */
  width: 100%;
  display: flex;
  column-gap: 5%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.navbar_right_link_style {
  cursor: pointer;
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  padding-bottom: 2px;
  background-position: 0 100%;
  background-size: 2% 2px;
  background-repeat: no-repeat;
  transition: color 0.2s ease-out, background-size 0.3s,
    background-position 0s 0.3s; /*change after the size immediately*/
}

.navbar_right_link_style:hover {
  background-image: linear-gradient(#686ea2 0 0);
  color: #686ea2;
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% 2px;
}

.navbar_dropdown {
  display: none;
  position: absolute;
  /* top: 64px;
  right: 279px; */
  top: 120px;
  /* right: 220px; */
  flex-direction: column;
  /* min-width: 150px;
  max-width: 230px; */
  /* this width is for brand */
  height: 290px;
  /* border: 2px solid red; */
  justify-content: space-evenly;
  /* background-color: black; */
  background-color: white;
  color: grey;
  text-align: left;
  gap: 2%;
  padding: 1%;
  border-radius: 10px;
}

.navbar_dropdown_onScroll {
  top: 80px;
}

.navbar_dropdown_on_login {
  right: 350px;
}

.navbar_dropdown_profile {
  display: none;
  position: absolute;
  top: 120px;
  /* right: 8px; */
  flex-direction: column;
  width: 150px;
  height: 100px;
  border: 2px solid black;
  justify-content: space-evenly;
  background-color: black;
  color: grey;
  text-align: left;
  gap: 2%;
  padding: 1%;
  border-radius: 10px;
  z-index: 100;
}
.navbar_dropdown_profile_onScroll {
  top: 80px;
}

.navbar_services:hover .navbar_dropdown,
.navbar_dropdown:hover,
.navbar_admin:hover .navbar_dropdown_profile,
.navbar_dropdown_profile:hover {
  display: flex;
  z-index: 10;
}
.navbar_dropdown_heading {
  text-decoration: none;
  color: black;
}
.navbar_dropdown_heading:hover {
  color: #686ea2;

}

.navbar_dropdown_section {
  color: #757575;
  text-decoration: none;
}

.navbar_dropdown_section:hover {
  background-image: none;
  /* color: white; */
  color: black;
  font-weight: 400;
  margin-left: 30px;
  font-weight: 500;
  transform: scale(1.12);
  /* margin-left: 2%; */
  /* transition: margin-left 0.2s; */
}

.navbar_search {
  cursor: pointer;
}

/* hamburger start */
.hamburger_menu_wrapper {
  display: none;
  position: fixed;
  top: 100px;
  right: -100%;
  height: 350px;
  width: 80%;
  background: black;
  color: white;
  transition: all 0.6s ease-in-out;
  z-index: 3;
}

.hamburger_service_dropdown {
  display: flex;
  flex-direction: column;
}

.hamburger_service_dropdown_trail_1 {
  /* border: 2px solid red; */
  margin-top: 10%;
  margin-left: 10%;
  width: 100%;
  display: none;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}
.hamburger_service_dropdown_trail_1_change {
  display: flex;
  flex-direction: column;
}

.hamburger_service_dropdown ul li {
  /* height: 10%; */
  margin: 15px 0;
}

/* for hamburger brand start*/
.hamburger_brand_dropdown {
  /* border: 2px solid red; */
  margin-top: 10%;
  margin-left: 10%;
  width: 100%;
  display: none;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}
.hamburger_brand_dropdown_change {
  display: flex;
  flex-direction: column;
}
/* for hamburger brand end */

#hamburger_menu_active:checked ~ .hamburger_menu_wrapper {
  right: 0;
}

.hamburger_menu_btn {
  display: none;
  position: absolute;
  z-index: 2;
  /* right: 60px; */
  right: 12px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.hamburger_menu_btn span,
.hamburger_menu_btn:before,
.hamburger_menu_btn:after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: 30%;
  width: 40%;
  border-bottom: 2px solid #757575;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger_menu_btn:before {
  transform: translateY(-8px);
}

.hamburger_menu_btn:after {
  transform: translateY(8px);
}

.hamburger_close {
  display: none;
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: background 0.6s;
}

/* closing animation */
#hamburger_menu_active:checked + .hamburger_menu_btn span {
  transform: scaleX(0);
}

#hamburger_menu_active:checked + .hamburger_menu_btn:before {
  transform: rotate(45deg);
  border-color: #757575;
}

#hamburger_menu_active:checked + .hamburger_menu_btn:after {
  transform: rotate(-45deg);
  border-color: #757575;
}

.hamburger_menu_wrapper_ul {
  position: absolute;
  top: 0;
  left: 4%;
  height: 95%;
  /* width: 60%; */
  width: 90%;
  list-style: none;
  /* text-align: center; */
  text-align: left;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.hamburger_menu_wrapper ul li {
  height: 10%;
  margin: 15px 0;
}

input[type="checkbox"] {
  display: none;
}

/* hamburger end */

/* navbar css end */

/* home css start */

.home_page_banner_container {
  max-width: 100%;
  height: 400px;
  /* background-image: linear-gradient(rgba(6, 14, 35, 0.5), rgba(6, 14, 35, 0.5)),
    url(./assets/main-banner-1.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: left;
  padding: 8% 37% 5% 10%;
  align-items: flex-start;
  gap: 3%;
}

.home_banner_p_1 {
  font-size: 30px;
  line-height: 1.3em;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 3px;
}

.home_banner_p_2 {
  font-size: 18px;
  line-height: 1.7;
  font-weight: 400;
  color: #ffffff;
}

.about_brief_main_container {
  background: #686ea2;
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-right: auto;
  color: white;
  margin-top: -130px;
  margin-left: 10%;
  z-index: 2;
  position: absolute;
  text-align: left;
  border-radius: 15px;
  /* top: 50%; */
  /* top: 80%; */
  /* top: 626px; */
  max-width: 1296px;
  left: 40%;
  transform: translate(-50%, 0%);
  cursor: pointer;
}

.abcd {
  background: #686ea2;
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-right: auto;
  color: white;
  /* margin-top: -7%; */
  margin-left: 10%;
  z-index: 2;
  position: absolute;
  text-align: left;
  border-radius: 15px;
  /* ************** */
  /* top: 50%; */
  /* max-width: 1296px;
  left: 40%;
  transform: translate(-50%, -50%); */
  /* top: 80%; */
  top: 626px;
  max-width: 1296px;
  left: 42%;
  transform: translate(-50%, 0%);
}

.about_brief_container {
  padding: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;
}

.about_brief_container_img {
  width: 25%;
  filter: invert(1);
  max-width: 82px;
  max-height: 82px;
  min-width: 50px;
  min-height: 50px;
}

.about_brief_container_title,
.section_3_card_title {
  margin-top: 2%;
  font-size: 20px;
  font-weight: 700;
}
.footer_upper_right_title {
  font-size: 20px;
  font-weight: 700;
}

.about_brief_container_description,
.section_3_card_description,
.footer_upper_right_description {
  margin-top: 2%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* line-clamp: 7; */
  -webkit-box-orient: vertical;
}

/* home section_2 start */

.section_2,
.about_section_3 {
  z-index: 1;
  width: 100%;
  background-image: url("http://finix.powersquall.com/it-solution/wp-content/uploads/sites/9/2021/09/section-patter-2.jpg");
  height: 865px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 8%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.section_2_about_banner_container {
  /* border: 2px solid red; */
  width: 50%;
  height: 70%;
}
.section_2_group_top_image {
  /* border: 2px solid red; */
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 2%;
  padding-bottom: 2%;
}
.section_2_group_top_left_image {
  /* border: 2px solid black; */
  /* width: 40%;
  height: 60%; */
  /* width: 62%;
  height: 60%; */
  /* width: 48%;
  height: 65%; */
  width: 40%;
  height: 67%;
}
.section_2_group_top_right_image {
  /* border: 2px solid yellow; */
  width: 50%;
  height: 90%;
  border-radius: 2%;
}
.section_2_group_bottom_image {
  /* border: 2px solid black; */
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 2%;
  padding-top: 2%;
}
.section_2_group_bottom_left_image {
  /* border: 2px solid green; */
  /* width: 40%; */
  width: 50%;
  height: 60%;
}
.section_2_group_bottom_right_image {
  /* border: 2px solid blue; */
  width: 46%;
  height: 89%;
}
.section_2_group_image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 5px 4px 4px;
}

.section_2_about_text_container,
.about_section_3_about_text_container {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  justify-content: center;
  gap: 2%;
  height: 59%;
}

.section_2_p_1,
.section_4_heading,
.section_5_heading,
.prefooterSection_heading,
.about_section_3_p_1,
.about_section_4_banner_p_1,
.contact_section_3_p_1,
.career_section_3_left_upper_p_1,
.webdesigndevelopment_section_4_heading {
  display: inline-block;
  position: relative;
  font-size: 13px;
  line-height: 18px;
  color: #fc692b;
  font-weight: 700;
  letter-spacing: 3px;
  margin-bottom: 10px;
  padding-left: 1%;
  text-transform: uppercase;
  color: #686ea2;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  justify-content: flex-start;
}

.section_2_p_2,
.section_4_title,
.section_5_title,
.prefooterSection_title,
.about_section_3_p_2,
.contact_section_3_p_2,
.career_section_3_left_upper_p_2,
.webdesigndevelopment_section_4_title {
  font-size: 40px;
  font-weight: 800;
  line-height: 1.2;
}

.section_2_p_3,
.about_section_3_p_3,
.contact_section_3_p_3,
.career_section_3_left_upper_p_3 {
  margin-top: 2%;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 400;
  color: #757575;
}

.section_2_button {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  color: #ffffff;
  background-color: #686ea2;
  width: 30px;
  width: 155px;
  height: 49px;
  border-radius: 4px;
  border: none;
  width: 155px;
  height: 49px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: color 1s ease-out, background-color 1s ease-out;
}

.section_2_button:hover {
  color: black;
  background-color: #ffffff;
}

/* home section 3 start */

.section_3_container {
  padding: 50px 0px 50px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f2f7fa;
}

.section_3_upper {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.section_3_upper_left {
  width: 45%;
  text-align: left;
}

.section_3_upper_left_hr {
  background-color: #686ea2;
  width: 10%;
  height: 4px;
}

.section_3_hr {
  height: 180px;
}

.section_3_upper_right {
  width: 45%;
  text-align: left;
}

.section_3_lower {
  width: 90%;
  /* margin-top: 2%; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  gap: 17px;
  margin: 2% auto 0 auto;
}

.section_3_card_icon {
  width: 20%;
  object-fit: cover;
}

.section_3_card_icon > img {
  width: 100%;
}

.section_3_card_description {
  color: #757575;
}

.flip_card {
  background-color: transparent;
  width: 300px;
  height: 250px;
  border-radius: 10px;
}

.flip_card_inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.flip_card:hover .flip_card_inner {
  transform: rotateY(180deg);
}

.flip_card_front,
.flip_card_back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
}

.flip_card_front {
  background-color: white;
  border-radius: 10px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
}

.flip_card_back {
  background-color: #686ea2;
  color: black;
  transform: rotateY(180deg);
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.flip_card_back_button {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  color: black;
  background-color: white;
  width: 30px;
  width: 155px;
  height: 49px;
  border-radius: 4px;
  border: none;
  width: 155px;
  height: 49px;
  border-radius: 4px;
  cursor: pointer;
  transition: color 1s ease-out, background-color 1s ease-out;
}

.flip_card_back_button:hover {
  color: white;
  background-color: black;
}

.flip_card_back div {
  /* font-size: larger; */
  font-weight: 100;
  cursor: pointer;
  transition: font-weight 0.3s;
}
.flip_card_back div:hover {
  /* font-size: x-large; */
  font-weight: 1000;
}

/* home section 3 end */

/* home section_4 start */
.section_4 {
  padding-top: 2%;
  background-color: white;
}

.section_4_carousel {
  /* height: 500px; */
  padding: 2% 0%;
  background-color: white;
}

/* home section 4 end */

.section_5 {
  padding-top: 2%;
  background-color: white;
}

.section_5_carousel {
  /* height: 200px; */
  padding: 2% 0%;
}

/* prefooterSection start */

.prefooterSection {
  width: 100%;
  padding: 70px 0px 70px 0px;
  background-color: #686ea2;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.prefooterSection_left {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.prefooterSection_heading {
  color: white;
  text-align: left;
}

.prefooterSection_title {
  color: white;
  font-size: 36px;
  font-weight: 700;
  text-align: left;
}

.prefooterSection_right {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2%;
}

.prefooterSection_right_contact_button {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  color: black;
  background-color: white;
  border: none;
  width: 155px;
  height: 49px;
  border-radius: 4px;
  cursor: pointer;
  transition: color 1s ease-out, background-color 1s ease-out;
}

.prefooterSection_right_contact_button:hover {
  background-color: black;
  color: white;
}

/* prefooterSection end */
/* footer start */

.footer_container {
  /* height: 800px; */
  position: inherit;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  width: 100%;
  padding: 60px 0px 0px 0px;
  z-index: -100;
}

.footer_upper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: white;
}

.footer_upper_left,
.footer_upper_right {
  width: 30%;
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-around; */
  justify-content: flex-start;
  gap: 6%;
}

.footer_upper_left_logo {
  max-width: 278px;
}

.footer_upper_mid {
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-around; */
  justify-content: flex-start;
  gap: 6%;
}

.footer_upper_left_description,
.footer_upper_right_description {
  text-align: left;
  color: #a7acb7;
  font-size: 16px;
  font-weight: 400;
}
.footer_upper_left_icon_container {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d0e0ff;
  cursor: pointer;
}

.footer_lower {
  color: #a7acb7;
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0px;
}

.footer_lower_left {
  width: 50%;
  text-align: left;
}

.footer_lower_right {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4%;
}

.footer_lower_right_p {
  cursor: pointer;
  color: #a7acb7;
  text-decoration: none;
}

.footer_lower_right_p:hover {
  color: red;
}

/* footer section end */

/* common banner section start */

.common_banner_container {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(./assets/common-banner-image.jpg); */
  height: 350px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: white;
}

.common_banner_container_title {
  font-size: 50px;
  font-weight: 700;
  word-wrap: break-word;
  text-align: center;
}

.common_banner_container_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.8%;
}
.common_banner_para {
  font-size: 16px;
}

.common_banner_container_div_link {
  display: flex;
  color: white;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.common_banner_container_div_link:hover {
  color: #3270fc;
}

.common_banner_container_div_p {
  color: #3270fc;
}
/* common banner section end */

/* about page start */
.about_section_3_about_banner_container {
  width: 50%;
}

.about_section_3 {
  padding: 5% 0% 5% 0%;
  height: 500px;
}

.about_section_3_about_text_container {
  gap: 10px;
  height: 90%;
}

.about_section_4_banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(./assets/about_banner_2.jpg);
  height: 50vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  align-items: flex-start;
  text-align: left;
  padding: 3% 35% 3% 8%;
}

.about_section_4_banner_p_2 {
  color: white;
  line-height: 26px;
  font-size: 18px;
  font-weight: 400;
}

/* about page end */

/* contact page start */

.contact_card_container {
  cursor: pointer;
  width: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  /* padding: 2% 5%; */
  padding: 2%;
  background-color: white;
  border-radius: 10px;
  justify-content: space-evenly;
}

.contact_card_icon {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background: rgb(104, 110, 162);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact_section_3 {
  background-color: #f9f9f9;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2% 25%;
  gap: 2%;
  height: auto;
}

.contact_section_4_lower {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  gap: 15px;
  padding: 2% 1%;
  background-color: #f9f9f9;
}

/* section 5 form start */
.contact_section_5 {
  height: 800px;
  background-color: #f9f9f9;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.section_5_left {
  height: 90%;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.section_5_left_map {
  border: 1px solid blue;
  height: 48%;
  width: 100%;
}

.section_5_right {
  height: 90%;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.contact_form_heading_title {
  align-items: flex-start;
  padding: 2% 0;
  text-align: left;
}

.contact_form_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form_input_container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
}

/* .form_input_container div, */
.form_input_container input,
.radio {
  border: 1px solid;
  border-radius: 5px;
  height: 45px;
  font-size: 100%;
  background-color: transparent;
  padding-left: 1%;
}

.radio {
  vertical-align: middle;
  line-height: 45px;
}

.form_radio_option {
  border: 2px solid;
  position: absolute;
  background-color: white;
  margin-top: 4.4%;
  width: 47%;
  border-radius: 10px;
  padding: 1% 0% 1% 1%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 25px;
}

.form_radio_option div {
  width: 100%;
}

.form_radio_option div:hover {
  background-color: blue;
  width: 100%;
  cursor: pointer;
}

.form_input_container textarea {
  border: 1px solid;
  border-radius: 5px;
  font-size: 100%;
  background-color: transparent;
  padding: 1% 0% 0% 1%;
}

.form_input_container_button {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
}

.form_input_container_button input {
  border: 1px solid;
  border-radius: 5px;
  height: 40px;
  font-size: 100%;
  background-color: transparent;
  width: 150px;
  background-color: #686ea2;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
}

.form_input_container_button input:hover {
  background-color: white;
  border: 2px solid #686ea2;
  color: #686ea2;
}

/* section 5 form end */

/* contact page end */

/* career section start */

.career_section_3_container {
  height: 700px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  /* border: 2px solid red; */
  padding: 2% 0 0 0;
}

.career_section_3_left {
  width: 58%;
  height: 100%;
  /* border: 2px solid green; */
}

.career_section_3_left_upper {
  background-color: #f9f9f9;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2% 0% 2% 0%;
  gap: 2%;
  height: auto;
  /* border: 2px solid blue; */
}

.career_section_3_form_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.career_section_3_form_input_container {
  width: 50%;
  margin-top: 2%;
}

.career_section_3_form_input_double_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  gap: 2%;
  margin-left: auto;
  margin-right: auto;
}

.career_section_3_form_input_single_container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-top: 2%;
}

.career_section_3_form_input_container input,
.career_section_3_form_input_single_container input {
  height: 40px;
  width: 98%;
  border-radius: 5px;
  font-size: 100%;
  margin-top: 10px;
  border: 1px solid;
  background-color: transparent;
  text-decoration: none;
}

.career_section_3_form_submit_button input {
  margin: 10px 0px;
  border: none;
  border-radius: 5px;
  height: 40px;
  font-size: 100%;
  background-color: transparent;
  width: 150px;
  background-color: #686ea2;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
}

.career_section_3_form_submit_button input:hover {
  background-color: white;
  border: 2px solid #686ea2;
  color: #686ea2;
}

.career_section_3_right {
  /* border: 1px solid black; */
  width: 28%;
}
/* career section end */

/* all services common section start */

.all_services_common_section_container {
  /* border: 2px solid; */
  /* background-color: #f9f9f9; */
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  /* padding: 2% 4%; */
  padding: 0% 0% 2% 0;
  justify-content: space-around;
}

.all_services_common_section_left {
  width: 90%; /* new changes */
}

.all_services_common_section_left_upper {
  padding: 4% 0 2% 0;
  font-size: 30px;
  line-height: 1.3em;
  font-weight: 800;
}

.all_services_common_section_left_lower {
  text-align: left;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 400;
}

.all_services_common_section_left_lower div {
  margin-top: 2%;
  white-space: pre-line;
}

.all_services_common_section_left_upper span {
  border: 1px solid green;
}

.all_services_common_section_right {
  width: 30%;
  border: 1px solid black;
}

/* all services common section end */

/* web design development service start */
.webdesigndevelopment_section_4 {
  background-color: #f9f9f9;
  padding: 2% 0%;
}

.webdesigndevelopment_section_card_container {
  /* background-color: #f9f9f9; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* flex-direction: column; */
  /* flex-wrap: nowrap; */
  justify-content: center;
  height: auto;
  gap: 10px;
  padding: 2% 3%;
  justify-content: space-evenly;
}

.section_3_lower {
  width: 90%;
  margin-top: 2%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  gap: 17px;
  padding: 2% 0;
}

.webdesigndevelopment_card_container {
  width: 95%; /*after change*/
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  /* padding: 2% 5%; */
  padding: 2%;
  text-align: left;
  background-color: white;
  border-radius: 10px;
  justify-content: space-evenly;
  /* border: 2px solid; */
}

.webdesigndevelopment__card_icon {
  /* width: 10%; */
  width: 70px;
  height: 70px;
  max-width: 70px;
  object-fit: cover;
  background-color: #686ea2;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.webdesigndevelopment__card_icon > img {
  filter: invert(1);
  width: 60%;
}
.webdesigndevelopment_card_description {
  margin-top: 2%;
  color: #757575;
}

.section_3_card_description {
  color: #757575;
}

/* web design development service end */

/* media query */
@media only screen and (max-width: 1700px) {
  .navbar_dropdown_profile {
    right: 20px;
  }
}
@media only screen and (max-width: 1537px) {
  .navbar_dropdown_profile {
    right: 8px;
  }
}

@media only screen and (max-width: 990px) {
  .top_left_section {
    width: 49%;
    font-size: 1.4vw;
  }
  .top_right_section {
    width: 49%;
    font-size: 1.4vw;
  }
  .navbar_right_link_style {
    color: white;
  }

  .hamburger_menu_btn,
  .hamburger_close,
  .hamburger_menu_wrapper {
    display: block;
  }

  .navbar_right_link {
    display: none;
  }

  /* home page start */
  .home_page_banner_container {
    padding: 4% 3% 5% 3%;
    gap: 4%;
  }

  .section_2 {
    padding: 24% 0% 10% 0%;
    flex-direction: column;
    width: 100%;
    height: 1000px;
    gap: 5%;
  }

  .about_brief_container_img {
    width: 30%;
  }

  .section_2_about_banner_container {
    width: 100%;
  }

  .section_2_about_text_container {
    width: 95%;
  }

  .section_2_p_2,
  .section_4_title,
  .section_5_title,
  .prefooterSection_title,
  .about_section_3_p_2,
  .contact_section_3_p_2,
  .career_section_3_left_upper_p_2,
  .webdesigndevelopment_section_4_title {
    font-size: 38px;
    font-weight: 800;
    line-height: 1.2;
  }

  .section_3_upper {
    flex-direction: column;
    width: 100%;
  }

  .section_3_upper_left {
    width: 95%;
  }

  .section_3_hr {
    display: none;
  }

  .section_3_upper_right {
    width: 95%;
  }

  .contact_card_container {
    width: 43%;
  }

  .prefooterSection {
    flex-direction: column;
  }

  .prefooterSection_left {
    width: 95%;
  }

  .prefooterSection_right {
    margin-top: 2%;
    width: 95%;
    justify-content: flex-start;
  }
  /* home page end */

  /* about page start */

  .about_section_3 {
    height: auto;
    flex-direction: column;
  }

  .about_section_3_about_banner_container {
    width: 95%;
  }

  .about_section_3_about_text_container {
    width: 95%;
  }

  .about_section_4_banner {
    padding: 5% 2%;
    height: auto;
  }

  /* about page end */
  /* career page start */
  .career_section_3_container {
    flex-direction: column;
    height: auto;
  }

  .career_section_3_left {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .career_section_3_right {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  /* career page end */

  /* contact us page start */

  .contact_section_3 {
    padding: 2% 0%;
  }

  .contact_section_5 {
    flex-direction: column;
    height: auto;
  }

  .section_5_left,
  .section_5_right {
    width: 95%;
    height: 800px;
  }
  /* contact us page end */

  /* all service page start */
  .webdesigndevelopment_section_card_container {
    align-items: center;
    flex-wrap: wrap;
  }
  .webdesigndevelopment__card_icon {
    width: 20%;
  }
  .webdesigndevelopment_card_container {
    width: 95%;
  }
  /* all service page end */

  /* footer start */
  .footer_container {
    height: auto;
  }

  .footer_upper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  .footer_lower {
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .footer_upper_left {
    width: 95%;
    padding-left: 2%;
    row-gap: 20px;
    height: auto;
  }
  .footer_upper_mid {
    height: auto;
    padding-left: 2%;
    row-gap: 10px;
  }
  .footer_upper_right {
    width: 95%;
    padding-left: 2%;
    height: auto;
  }
  .footer_lower_left {
    width: 95%;
  }

  .footer_lower_right {
    width: 95%;
    justify-content: flex-start;
  }

  /* footer end */
}

@media only screen and (max-width: 770px) {
  .navbar_left {
    width: 40%;
  }
  .navbar_right {
    width: 40%;
  }
  .top_left_section {
    width: 49%;
    font-size: 1.8vw;
  }
  .top_right_section {
    width: 49%;
    font-size: 1.8vw;
  }
  /* home page start */

  .home_page_banner_container {
    padding: 4% 3% 5% 3%;
    gap: 6%;
  }

  .section_2 {
    padding: 5% 0% 10% 0%;
    flex-direction: column;
    width: 100%;
    height: 1000px;
  }

  .section_2_about_banner_container {
    width: 100%;
  }

  .section_2_about_text_container {
    width: 95%;
    height: auto;
  }

  .section_2_p_2,
  .section_4_title,
  .section_5_title,
  .prefooterSection_title,
  .about_section_3_p_2,
  .contact_section_3_p_2,
  .career_section_3_left_upper_p_2,
  .webdesigndevelopment_section_4_title {
    font-size: 28px;
    font-weight: 800;
    line-height: 1.2;
  }

  .about_brief_main_container {
    flex-direction: column;
    width: 94%;
    border: 7px solid white;
    margin: 2% auto;
    position: unset;
    transform: none;
  }

  .about_brief_container {
    width: 90%;
  }

  .about_brief_container_img {
    width: 13%;
  }

  .contact_card_container {
    width: 75%;
  }

  /* home page end */
  /* common banner section start */
  .common_banner_container_title {
    font-size: 6vw;
  }
  .common_banner_para {
    font-size: 2.5vw;
  }
  /* common banner section end */

  /* service page start */

  .webdesigndevelopment_section_card_container {
    flex-direction: column;
    /* flex-direction: row; */
    align-items: center;
    flex-wrap: wrap;
  }
  .webdesigndevelopment_card_container {
    width: 95%;
  }

  .flip_card {
    width: 462px;
    height: 319px;
  }

  /* service page end */
}

@media only screen and (max-width: 473px) {
  .navbar_left {
    width: 60%;
  }
  .navbar_right {
    width: 30%;
  }
  /* home page start */

  .home_banner_p_1 {
    font-size: 6vw;
  }

  .home_banner_p_2 {
    font-size: 4vw;
  }
  /* home page end */

  /* common banner section start */
  .common_banner_container_title {
    font-size: 8vw;
  }

  .common_banner_para {
    font-size: 4vw;
  }

  /* common banner section end */
}

@media only screen and (max-width: 300px) {
  .home_banner_p_1 {
    font-size: 8.5vw;
  }

  .home_banner_p_2 {
    font-size: 5vw;
  }
  /* footer css start */
  .footer_upper_left_logo {
    width: 100%;
  }
  .footer_lower {
    font-size: 4vw;
  }
  /* footer css end */
}
